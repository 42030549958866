import React from "react";

const Portfolio = ({ data }) => {

  if (!data || !data.projects) {
    console.error("Data or projects are undefined");
    return null; // or return a fallback UI
  }

  // console.log(`this is the data:${JSON.stringify(data.projects)}`);
  const { projects } = data;
  //console.log(`this is the projects:${JSON.stringify(projects)}`);
  console.log(`this is the projects:${projects.length}`);

const projectTypes = ["My Playground"];

  const renderProjects = (items) => {
  if (items) {
    return (
      items.filter(i => i.disabled !== true)
        .sort((a, b) => a.sort - b.sort)
        .map(function (item) {
          console.log(`this is the item:${JSON.stringify(item)}`);
        var projectImage = "images/portfolio/" + item.image;
        return (
          <div key={item.title} className="columns portfolio-item">
            <div className="item-wrap">
              <a href={item.url} title={item.title} target="_blank" el="noreferrer" rel="noreferrer" >
                <img alt={item.title} src={projectImage} />
                <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{item.title}</h5>
                    <p>{item.category}</p>
                  </div>
                </div>
                <div className="link-icon">
                  <i className="fa fa-link"></i>
                </div>
              </a>
            </div>
          </div>
        );
      })
    );
  }}

  return (
    <section id="portfolio">
      {
        projectTypes.map((projectType) => {
          return (
            <div className="row">
              <div className="twelve columns collapsed">
                <h1>{projectType}</h1>
                <div
                  id="portfolio-wrapper"
                  key={projectType}
                  className="bgrid-quarters s-bgrid-thirds cf">
                  { renderProjects(projects.filter((project) => project.type === projectType)) }
                </div>
              </div>
          </div>
      )})}
    </section>
  );
};

export default Portfolio;
